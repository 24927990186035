import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/pages/about.scss';
import aboutImage from '../assets/images2/about-image.png';
import Logicboy from '../assets/images2/Logicboy.png';
import valuation_basis from '../assets/images2/valuation_basis.png';
import Logicboymoving from '../assets/images2/Logicboymoving.gif';

const About = () => {
    const [isGif, setIsGif] = useState(false);
    const [isMobile, setIsMobile] =useState(false);

    const handleImageToggle = () => {
        setIsGif(!isGif);
    };

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768); // 幅が768px以下の場合はモバイル
        };

        checkMobile(); // 初期チェック
        window.addEventListener('resize', checkMobile); // ウィンドウサイズが変わった時もチェック

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return (
        <div className="container">
            <Helmet>
                <title>このサイトについて | ロジレビ</title>
            </Helmet>
            
            <h1>このサイトについて</h1>
            
            <div className="image-container">
                <img 
                    src={aboutImage} 
                    alt="日本国内のファクトチェック記事本数の推移の棒グラフ" 
                    className="about_image zoomed"
                />   
                <a href="https://fij.info/introduction" target="_blank" rel="noopener noreferrer" className="source-link">
                    fij.info
                </a>
            </div>

            <h2 className="color">ロジレビの目的</h2>

            <p>ロジレビの目的は、社会全体のメディアリテラシーの向上です。COVID-19パンデミックを機に、多くの人々が正確な情報を求めるようになり、事実確認（ファクトチェック）に対する意識が高まっています。＜事実（ファクト）＞と呼ばれるものは、常に社会的な合意や信用によって支えられています。そして、特定の基準に基づいて導き出されたものであるため、相対的な側面を持ち続けます。データを分析し科学的に検証され、客観的な基準によって立証されたものであっても、それを受け取る私たちが多様に存在するので、絶対的に振る舞うとは限りません。</p>
            <p>事実が社会的な信用に支えられていること、そしてその信用が特定の基準に基づいていることを忘れれば、既に信用を得ている強力な主体が、何が事実かを一方向的に決める力を持つリスクを孕むことになります。情報の相対性を認識することは、客観的な視点やその重要性を否定するものではありません。むしろ、私たち一人一人が情報を多角的に解析し、その客観性を検証し精査する力を養うことが重要であることを示しています。</p>
            <p>同時に、多様な解釈を無批判に受け入れるのではなく、どのような解釈や意見に対しても検証する姿勢を育むことも大切です。そこで、ロジレビではニュース記事を5つの観点をもとに5段階評価でスコア化し、明文化することで、ユーザーに情報を精査する視点を提供することを目指しています。情報の判断力が社会全体で向上することで、フェイクニュースの拡散は最小限に抑えられ、メディアが提供する情報の質も向上していくことでしょう。</p>
            <a href="https://globe.asahi.com/article/13918604" target="_blank" rel="noopener noreferrer" className="asahi_link">
                <div className="related article">
                    <p className="fp">関連記事</p>
                    <p>「メディアリテラシーは誤用されている」　フェイクを見抜くよりも大切なこと：朝日新聞GLOBE＋</p>
                </div>
            </a>

            <h2 className="color">ロジレビの機能</h2>
                <p>・ロジレビ評価 - 情報の信憑性、論理性、客観性、公平性、多角性を5段階評価でスコア化します。総合評価（ロジレビ評価）は、各スコアの平均で算出しています。</p>
                <p>・記事の要約、掲載元情報の表示</p>
                <p>・SNS連携 - 各種SNS、メール、ノートなどへシェアできます。</p>
                <p>以上を基本機能とし、皆さまのご要望にお応えし、機能を充実させて参ります。</p>
            <img src={valuation_basis} alt="評価基準のイメージ" className="valuation_basis zoomed" />

            <h2 className="color">評価項目について</h2>

            <p>1.信憑性 - 記事がどれだけ信頼できるかを評価します。情報源の信頼性や、記事の事実が正確であるかどうかがポイントです。誤情報や偏った情報が含まれていないかをチェックします。</p>
            <p>2.論理性 - 記事の内容が論理的に整合しているかを評価します。論理の飛躍や矛盾がないかを確認し、情報が一貫して提示されているかどうかをチェックします。</p>
            <p>3.客観性 - 記事が客観的な視点で書かれているかを評価します。著者のバイアスや個人的な偏見が反映されていないかを確認します。</p>
            <p>4.公平性 - 記事が公平な視点で書かれているかを評価します。特定の立場や意見に偏ることなく、バランスの取れた視点が提供されているかどうかをチェックします。</p>
            <p>5.多角性 - 記事が異なる視点や意見を多角的に提示しているかを評価します。問題やトピックに対して、さまざまな視点やアプローチが示されているかを確認し、偏った一方的な見解に終わっていないかをチェックします。</p>
            <p>以上の項目について評価を行います。また、皆さまのフィードバックを反映し、評価基準の改善に努めていきます。</p>

            <h2 className="color">運営者情報</h2>

            <div className="administrator-container">
                <div className="Logicboy_card">
                    <img src={isGif ? Logicboymoving : Logicboy} alt="ロジレビくん" className="logicboy-image" />
                    <button onClick={handleImageToggle} className="toggle-button">
                        {isGif ? '休憩' : 'ダンス'}    
                    </button>
                    <div className="text">                   
                    <p>公式キャラクター - ロジレビくん</p>
                    <p>好きな食べ物：偽情報(噛み砕いて処理するよ！)</p>
                    <p>特技：ダンス</p>
                    </div>
                </div>
            </div>

            <h2 className="color">今後の展望</h2>

            <h3>実装予定の機能</h3>
                <p>・週刊ハイライト - トップページには、本日と今週の主要ニュースのダイジェストを一目で確認できるように掲載します。皆さまに大きな影響を与えるニュースを厳選し、要点を凝縮してお届けします。ロジレビにアクセスするだけで、国内外の動向を瞬時に把握でき、これさえ見れば情報収集は万全です。</p>
                <p>・モア・インフォメーション機能 - 記事内に登場する各主体（人物・組織など）を抽出し、関連情報やプロフィールを表示します。</p>
                <p>・コメント機能 - コメントに対する反応は、いいねやバッドではない、新しいものにします。</p>
                <p>・コミュニティ機能</p>
                <p>・モバイルアプリ</p>
                <p>・記事の背景情報と解説機能 - 利害関係、対立構造をわかりやすく、図式化して表示したり、時事問題や事件などの経緯を整理します。</p>
                <p>・PVの増加 - アクセス数の向上に努めてまいります。</p>
                <p>・多言語対応</p>
                <p>・自動処理システムの改良</p>
            </div>
    );
}

export default About;
