import React, { useState } from 'react';
import '../styles/pages/support.scss';

const SupportPage = () => {
  const [amount, setAmount] = useState(5000);

  const handleSupportClick = (selectedAmount) => {
    setAmount(selectedAmount);
  };

  return (
    <main>
      <div className="support-container">
        <h1>準備中...</h1>
      </div>
    </main>
  );
};

export default SupportPage;
