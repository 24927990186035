import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import ResultPage from './components/ResultPage';
import About from './pages/About';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FeedBack from './pages/FeedBack';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import Updates from './pages/Updates';
import Support from './pages/Support';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta property="og:title" content="ロジレビで記事をレビューしました！" />
        <meta property="og:description" content="ロジレビで記事の評価結果を確認できます。" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="https://example.com/image.jpg" />
      </Helmet>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/results/:id" element={<ResultPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/support" element={<Support />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;