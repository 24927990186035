import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/pages/terms.scss';

const Terms = () => {
    return (
        <div className="container">
            <Helmet>
                <title>利用規約 | ロジレビ</title>
            </Helmet>
            <h1>利用規約</h1>
            <p>この利用規約（以下「本規約」）は、ロジレビ（以下「当サイト」）が提供するサービスの利用に関して、ユーザーの皆様に遵守していただく事項を定めたものです。本規約に同意いただいた上で、当サイトをご利用ください。</p>

            <h3>1. 利用者の責任</h3>
            <p>利用者は、当サイトを利用する際に、すべての適用される法律および規制を遵守しなければなりません。</p>

            <h3>2. 禁止事項</h3>
            <p>当サイトでは、以下の行為を禁止します。</p>
            <p>・法令や公序良俗に違反する行為</p>
            <p>・当サイトのシステムやセキュリティに対する不正アクセス行為</p>
            <p>・他の利用者や第三者の権利を侵害する行為</p>

            <h3>3. 知的財産権</h3>
            <p>当サイト内のすべてのコンテンツ（文章、画像、動画など）は、運営者である鶴田新一または適切なライセンサーに帰属します。許可なくこれらのコンテンツを利用、複製、配布することを禁じます。</p>

            <h3>4. 免責事項</h3>
            <p>当サイトの情報は、正確性や最新性を保証するものではありません。当サイトを利用することにより生じた損害について、運営者は一切の責任を負いません。</p>

            <h3>5. 個人情報の取り扱い</h3>
            <p>当サイトは、利用者の個人情報を適切に保護・管理いたします。詳しくはプライバシーポリシーをご確認ください。</p>

            <h3>6. 規約の変更</h3>
            <p>本規約は、運営者の判断により随時変更されることがあります。変更があった場合、当サイト上に掲示することにより利用者に通知します。</p>

            <h3>7. 準拠法および裁判管轄</h3>
            <p>本規約は日本法に準拠し、日本国内におけるサービス利用に関しては、横浜市の裁判所を第一審の専属的合意管轄裁判所とします。海外からの利用者については、該当する国や地域の法律が適用される場合があり、法的紛争が生じた場合は、利用者の所在する国や地域の裁判所が管轄することがあります。</p>

            <p>制定日:2024/09/22(日)</p>

        </div>
    );
}

export default Terms;
