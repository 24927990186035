import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';
import '../styles/chart.scss';
import ShareButtons from './ShareButtons';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);

const Chart = ({ onAnimationEnd, averageScore, evaluationData, publisher }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isShrunk, setIsShrunk] = useState(false);
    const [showShareButtons, setShowShareButtons] = useState(false);
    const [shouldApplyVisibleClass, setShouldApplyVisibleClass] = useState(false);

    // チャートアニメーションとシェアボタン表示
    useEffect(() => {
        const isMobile = window.innerWidth <= 670;
        const expandDelay = isMobile ? 100 : 800;
        const shrinkDelay = isMobile ? 400 : 2500;
        const finalDelay = isMobile ? 300 : 1300;

        const expandTimer = setTimeout(() => {
            setIsExpanded(true);
        }, expandDelay);

        const shrinkTimer = setTimeout(() => {
            setIsShrunk(true);
        }, expandDelay + shrinkDelay);

        const finalTimer = setTimeout(() => {
            setShowShareButtons(true);
            setShouldApplyVisibleClass(true);
            if (onAnimationEnd) onAnimationEnd();
        }, expandDelay + shrinkDelay + finalDelay);

        return () => {
            clearTimeout(expandTimer);
            clearTimeout(shrinkTimer);
            clearTimeout(finalTimer);
        };
    }, [onAnimationEnd]);

    const labeledScores = [
        { label: '信憑性', description: '情報の正確さ', score: parseFloat(evaluationData?.credibility?.score) || 0 },
        { label: '論理性', description: '論理的な構成', score: parseFloat(evaluationData?.logic?.score) || 0 },
        { label: '客観性', description: '偏見のない情報', score: parseFloat(evaluationData?.objectivity?.score) || 0 },
        { label: '公平性', description: '公正な視点', score: parseFloat(evaluationData?.fairness?.score) || 0 },
        { label: '多角性', description: '多様な視点', score: parseFloat(evaluationData?.diversity?.score) || 0 },
    ];

    const data = {
        labels: labeledScores.map(item => `${item.label} ${item.score}/5`),
        datasets: [
            {
                label: '評価結果',
                data: labeledScores.map(item => item.score),
                backgroundColor: '#74BCD4',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                pointStyle: 'line',
                pointRadius: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                angleLines: {
                    display: false,
                },
                ticks: {
                    display: false,
                    stepSize: 1,
                },
                suggestedMin: 0,
                suggestedMax: 5,
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false, 
            },
        },
    };

    return (
        <div className={`chart-container ${isExpanded ? 'expanded' : ''} ${isShrunk ? 'shrunk' : ''}`}>
            <div className={`average-score ${shouldApplyVisibleClass ? 'visible' : ''}`}>
                総合評価 : {averageScore}
            </div>
            <div className="chart-content">
                <Radar data={data} options={options} />
            </div>
            {showShareButtons && (
                <ShareButtons
                    url={window.location.href}
                    title={`${publisher}の記事をレビューしました！ロジレビ評価は${averageScore}。詳細はこちらからご覧ください！`}
                    averageScore={averageScore}
                    publisher={publisher || 'こ'} 
                />
            )}
        </div>
    );
};

export default Chart;
