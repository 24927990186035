import React, { useState } from 'react';
import '../styles/pages/feedback.scss';

const Feedback = () => {
    const [feedback, setFeedback] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rating, setRating] = useState(3);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        // フィードバックを既存のサーバーに送信
        fetch('/api/feedback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                email,
                rating,
                feedback
            })
        })
        .then((response) => {
            if (response.ok) {
                console.log('フィードバックが送信されました');
                setSubmitted(true);
            } else {
                setError('送信中にエラーが発生しました。もう一度お試しください。');
            }
        })
        .catch((error) => {
            console.error('エラー:', error);
            setError('ネットワークエラーが発生しました。もう一度お試しください。');
        });
    };
    
    return (
        <main>
            <h1>フィードバックをお寄せください</h1>
            <div className="feedback-container">
                {submitted ? (
                    <div className="thank-you-message">
                        <h2>ありがとうございます！</h2>
                        <p>フィードバックが送信されました。改善に役立てさせていただきます。</p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">お名前（任意）</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="お名前を入力"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">メールアドレス（任意）</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="メールアドレスを入力"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="rating">評価</label>
                            <input
                                type="range"
                                id="rating"
                                min="1"
                                max="5"
                                value={rating}
                                onChange={(e) => setRating(e.target.value)}
                            />
                            <p>{rating} / 5</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="feedback">フィードバック</label>
                            <textarea
                                id="feedback"
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                placeholder="ご意見・ご感想をお寄せください"
                                required
                            />
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        <button type="submit" className="submit-button">
                            送信
                        </button>
                        <p className="privacy-notice">
                            フィードバックに関するプライバシーについては、<a href="/PrivacyPolicy">プライバシーポリシー</a>をご確認ください。
                        </p>
                    </form>
                )}
            </div>
        </main>
    );
};

export default Feedback;
