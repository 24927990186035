import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/pages/privacypolicy.scss';

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <Helmet>
                <title>プライバシーポリシー | ロジレビ</title>
            </Helmet>
            <h1>プライバシーポリシー</h1>
            <p>このプライバシーポリシー（以下「本ポリシー」）は、ロジレビ（以下「当サイト」）が提供するサービスにおける個人情報の取扱いについて説明します。本ポリシーに同意の上、当サイトをご利用ください。</p>
            
            <h3>1. 収集する情報</h3>
            <p>当サイトでは、ユーザーの氏名、メールアドレス、IPアドレス、ブラウザ情報、クッキー情報などを収集することがあります。</p>
            
            <h3>2. 情報の利用目的</h3>
                <p>・サービス提供およびカスタマーサポート</p>
                <p>・サービス改善および分析</p>
                <p>・広告やマーケティングのための利用</p>

            <h3>3. クッキーの使用</h3>
            <p>当サイトでは、クッキーを利用して、ユーザーの利便性向上およびサイト改善を行っています。</p>
            
            <h3>4. 第三者への情報提供</h3>
            <p>当サイトは、法令に基づく場合を除き、ユーザーの個人情報を第三者に提供することはありません。</p>
            
            <h3>5. 情報の管理</h3>
            <p>当サイトは、適切なセキュリティ対策を講じ、収集した情報の不正アクセス、漏洩、紛失を防止します。</p>
            
            <h3>6. 利用者の権利</h3>
            <p>利用者は、当サイトが保有する個人情報にアクセスし、修正、削除を依頼する権利があります。</p>

            <h3>7. ポリシーの変更</h3>
            <p>本ポリシーは、随時変更されることがあります。変更後のポリシーは、当サイト上に掲示された時点から適用されます。</p>

            <h3>8. お問い合わせ</h3>
            <p>個人情報に関するお問い合わせは、support@logicreview.spaceまでご連絡ください。</p>
        </div>
    );
};

export default PrivacyPolicy;
