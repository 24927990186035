import React from 'react';
import '../styles/pages/faq.scss';

const FaqPage = () => {
    return (
        <main>
        <div className="faq-container">
            <h1>よくある質問（FAQ）</h1>
            
            <div className="faq-section">
                <h2>基本的な質問</h2>
                <details>
                    <summary>ロジレビって何ですか？</summary>
                    <p>ロジレビは、ニュース記事を5つの項目で評価し、記事の信憑性や信頼性を見極めるためのツールを提供しています。</p>
                </details>
                <details>
                    <summary>このサイトの利用は無料ですか？</summary>
                    <p>はい、すべてのサービスは無料でご利用いただけます。</p>
                </details>
            </div>

            <div className="faq-section">
                <h2>評価基準について</h2>
                <details>
                    <summary>ロジレビの評価はどうやって決まりますか？</summary>
                    <p>ロジレビでは、信憑性、論理性、客観性、公平性、多角性の5つの観点に基づいて、ニュース記事を評価しています。</p>
                </details>
                <details>
                    <summary>評価結果を修正することはできますか？</summary>
                    <p>現時点では、評価結果の修正は行っていませんが、フィードバックを受けて基準を見直すことがあります。</p>
                </details>
            </div>

            <div className="faq-section">
                <h2>フィードバックについて</h2>
                <details>
                    <summary>フィードバックはどうやって送信できますか？</summary>
                    <p>「フィードバック」ページから送信できます。いただいたフィードバックは、サービス改善に役立てています。</p>
                </details>
            </div>

            <div className="faq-section">
                <h2>その他の技術的な質問</h2>
                <details>
                    <summary>アカウントは必要ですか？</summary>
                    <p>アカウントを作成する必要はありません。どなたでも自由にご利用いただけます。</p>
                </details>
            </div>
        </div>
        </main>
    );
}

export default FaqPage;
