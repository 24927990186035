import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/NavBar.scss';

const NavBar = ({ isMobile }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="menu-container">
            {isMobile && (
                <button className="menu-button" onClick={toggleMenu}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </button>
            )}
            <nav className={`menu ${isMenuOpen ? 'open' : ''} ${isMobile ? 'mobile' : ''}`}>
                <Link to="/about" onClick={closeMenu}>このサイトについて</Link>
                <Link to="/terms" onClick={closeMenu}>利用規約</Link>
                <Link to="/privacypolicy" onClick={closeMenu}>プライバシーポリシー</Link>
                <Link to="/feedback" onClick={closeMenu}>フィードバック</Link>
                <Link to="/faq" onClick={closeMenu}>よくある質問（FAQ）</Link>
                <Link to="/contact" onClick={closeMenu}>お問い合わせ</Link>
                <Link to="/updates" onClick={closeMenu}>更新情報</Link>
                <Link to="/support" onClick={closeMenu}>サポート</Link>
            </nav>
        </div>
    );
}

export default NavBar;
