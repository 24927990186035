import React from 'react';
import '../styles/pages/updates.scss';

const UpdatesPage = () => {
    const updates = [
        { date: '2024/09/25', content: 'ロジレビのベータ版をリリースしました。' }
    ];

    return (
        <div className="updates-container">
            <h1>更新情報</h1>
            <div className="updates-list">
                {updates.map((update, index) => (
                    <div key={index} className="update-item">
                        <h3>{update.date}</h3>
                        <p>{update.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UpdatesPage;
