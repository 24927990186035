import React, { useState } from 'react';
import '../styles/pages/contact.scss';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // フォームのデータ送信処理
        fetch('/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then((response) => {
            if (response.ok) {
                console.log('お問い合わせが送信されました');
                setSubmitted(true);
            } else {
                console.error('送信中にエラーが発生しました');
            }
        })
        .catch((error) => {
            console.error('ネットワークエラーが発生しました:', error);
        });
    };
    

    return (
        <main>
        <div className="contact-container">
            <h1>お問い合わせ</h1>

            <div className="contact-form">
                {submitted ? (
                    <div className="thank-you-message">
                        <h2>お問い合わせありがとうございます！</h2>
                        <p>ご連絡いただきありがとうございます。お返事は、可能な限り早くお送りします。</p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">名前</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                placeholder="お名前を入力"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">メールアドレス</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                placeholder="メールアドレスを入力"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">メッセージ</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                                placeholder="メッセージを入力してください"
                            ></textarea>
                        </div>

                        <button type="submit" className="submit-button">送信</button>
                    </form>
                )}
            </div>
        </div>
        </main>
    );
};

export default ContactPage;
