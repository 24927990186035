import React from 'react';
import '../styles/ArticleQuote.scss';

const ArticleQuote = ({ data }) => {
    const {
        original_url: url = '記事を参照してください',
        published_time: publishedTime = '記事を参照してください',
        author = '記事を参照してください',
        media_org: mediaOrg = '記事を参照してください', 
        publisher = '記事を参照してください',
        category = '記事を参照してください',
        title = '記事を参照してください',
        image_url: imageUrl = null 
    } = data || {};

    console.log('ArticleQuote data:', data);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        if (isNaN(date.getTime())) {
            return '記事を参照してください';
        }
        const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
        const weekday = weekdays[date.getDay()];
        return date.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }) + ` (${weekday}) ` + date.toLocaleTimeString('ja-JP', {
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const handleImageError = (e) => {
        e.target.src = '/path/to/your/default/noimage.jpg'; // 実際の画像パスを確認
    };

    const handleClick = () => {
        if (url !== '記事を参照してください') {
            window.open(url, '_blank');
        }
    };

    const getTopDomain = (articleUrl) => {
        try {
            const parsedUrl = new URL(articleUrl);
            return parsedUrl.origin;
        } catch (error) {
            return null;
        }
    };

    const mediaOrgUrl = getTopDomain(url);

    const googleSearchUrl = (query) => `https://www.google.com/search?q=${encodeURIComponent(query)}`;

    return (
        <div className="article-quote">
            <div className="article-quote-header" onClick={handleClick} style={{ cursor: url !== '記事を参照してください' ? 'pointer' : 'default' }}>
                {imageUrl && (
                    <img 
                        src={imageUrl} 
                        alt={title} 
                        className="article-quote-image"
                        onError={handleImageError}
                    />
                )}
                <h3>
                    {title || '記事を参照してください'}
                </h3>
            </div>
            <div className="article-quote-content">
                <p>公開日時: {publishedTime !== '記事を参照してください' ? formatDate(publishedTime) : '記事を参照してください'}</p>
                <p>
                    ライター:&nbsp;
                    {author !== '記事を参照してください' ? (
                        <a href={googleSearchUrl(author)} target="_blank" rel="noopener noreferrer">
                            {author}
                        </a>
                    ) : '記事を参照してください'}
                </p>
                <p>
                    メディア機関:&nbsp;
                    {mediaOrgUrl ? (
                        <a href={mediaOrgUrl} target="_blank" rel="noopener noreferrer">
                            {mediaOrg}
                        </a>
                    ) : '記事を参照してください'}
                </p>
                <p>
                    発行元:&nbsp;
                    {publisher !== '記事を参照してください' ? (
                        <a href={googleSearchUrl(publisher)} target="_blank" rel="noopener noreferrer">
                            {publisher}
                        </a>
                    ) : '記事を参照してください'}
                </p>
                <p>カテゴリ: {category}</p>
            </div>
        </div>
    );
};

export default ArticleQuote;