import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/header.scss';
import logoDesktop from '../assets/images/logo.png';
import logoMobile from '../assets/images/logo_mob.png';
import NavBar from './NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 671);
    const navigate = useNavigate(); 

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 671);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLogoClick = () => {
        document.title = 'ロジレビ';
        navigate('/'); 
    };

    return (
        <header>
            <NavBar isMobile={isMobile} />
            <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                <img src={isMobile ? logoMobile : logoDesktop} alt="ロジレビのロゴ" onDragStart={(e) => e.preventDefault()} />
            </div>
            <nav>
                <Link to="/about">このサイトについて</Link>
                <FontAwesomeIcon icon={faLanguage} />
            </nav>
        </header>
    );
};

export default Header;
