import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.scss';

const Footer = () => {
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const toggleInfo = () => {
        setIsInfoVisible(!isInfoVisible);
    };

    useEffect(() => {
        // ウィンドウの高さを取得するイベントリスナーを設定
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <footer>
            {/* 高さが900px以上の場合は常に表示 */}
            {windowHeight > 1200 || (isInfoVisible && windowHeight <= 1200) ? (
                <div className="info">
                    <ul>
                        <li><Link to="/about">このサイトについて</Link></li>
                        <li><Link to="/terms">利用規約</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="/privacypolicy">プライバシーポリシー</Link></li>
                        <li><Link to="/feedback">フィードバック</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="/faq">よくある質問（FAQ）</Link></li>
                        <li><Link to="/contact">お問い合わせ</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="/updates">更新情報</Link></li>
                        <li><Link to="/support">サポート</Link></li>
                    </ul>
                </div>
            ) : null}

            {/* 高さが600px以下または601pxから900pxの時にボタンを表示 */}
            {windowHeight <= 1200 && (
                <div className="last">
                    <p>&copy;&nbsp;&nbsp;LogicReView βeta&nbsp;&nbsp;2024</p>
                    <button onClick={toggleInfo}>
                        <span className="arrow">{isInfoVisible ? '▲' : '▼'}</span>
                    </button>
                </div>
            )}
            {/* 900px以上の時にはボタンを表示しない */}
            {windowHeight > 1200 && (
                <p>&copy;&nbsp;&nbsp;LogicReView βeta&nbsp;&nbsp;2024</p>
            )}
        </footer>
    );
};

export default Footer;
